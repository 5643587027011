import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  APP_TEACHER_LOGIN_SCREEN,
  TEACHER_DASHBOARD,
  TEACHER_DASHBOARD_COMPLETED,
  APP_STUDENT_LOGIN_SCREEN,
  TEACHER_PROGRAM_DETAIL,
  STUDENT_DASHBOARD,
  STUDENT_PROGRAM_DETAIL,
  TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT,
  APP_VIDEO_PLAYER,
  APP_AUDIO_PLAYER,
  APP_PDF_VIEWER,
  STUDENT_PROGRAM_DETAIL_CONTENT,
  TEACHER_PROGRAM_DETAIL_CONTENT,
  APP_IMAGE_VIEWER,
  TEACHER_CHAT_LISTING,
  TEACHER_CHAT_PORTAL,
  STUDENT_CHAT_PORTAL,
  TEACHER_CHAT_PORTAL_GROUP_DETAIL,
  STUDENT_CHAT_PORTAL_GROUP_DETAIL,
  APP_WEB_RECORDINGS_VIDEO_PLAYER_STUDENT,
  APP_CLASS_RECORDINGS_VIDEO_PLAYER,
} from 'version-two/navigation/routes';
import { useLayoutEffect, useState, Suspense, lazy } from 'react';
import isUserAuthenticated from 'version-two/api/utils/isUserAuthenticated';
import useAppStore from 'version-two/store';
import getUserToken from 'version-two/api/utils/getUserToken';
import AppStudentLoginScreen from 'version-two/pages/auth/AppStudentLoginScreen/AppStudentLoginScreen';
import AppTeacherLoginScreen from 'version-two/pages/auth/AppTeacherLoginScreen/AppTeacherLoginScreen';
import TeacherProgramList from 'version-two/pages/teacherDashboard/TeacherProgramList/TeacherProgramList';
import TeacherProgramDetail from 'version-two/pages/teacherDashboard/TeacherProgramDetail/TeacherProgramDetail';
import StudentProgramList from 'version-two/pages/studentDashboard/StudentProgramList/StudentProgramList';
import TeacherProgramDetailSingleStudent from 'version-two/pages/teacherDashboard/TeacherProgramDetailSingleStudent/TeacherProgramDetailSingleStudent';
import AppAudioPlayer from 'version-two/pages/common/AppAudioPlayer/AppAudioPlayer';
import AppPdfViewer from 'version-two/pages/common/AppPdfViewer/AppPdfViewer';
import removeUserToken from 'version-two/api/utils/removeUserToken';
import AppImageViewer from 'version-two/pages/common/AppImageViewer/AppImageViewer';
import TeacherChatList from 'version-two/pages/teacherDashboard/TeacherChatList/TeacherChatList';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import StudentProgramDetailWrapper from 'version-two/pages/studentDashboard/StudentProgramDetailWrapper/StudentProgramDetailWrapper';
import AppVideoPlayerWrapper from 'version-two/pages/common/AppVideoPlayerWrapper/AppVideoPlayerWrapper';
import WebRecordingsVideo from 'version-two/pages/common/WebRecordingsVideo/WebRecordingsVideo';
import AppClassRecordingsWrapper from 'version-two/pages/common/AppClassRecordingsWrapper/AppClassRecordingsWrapper';
import { isTeacherEnvironment } from 'appConfig';
import TeacherCompletedProgram from 'version-two/pages/teacherDashboard/TeacherCompletedProgram/TeacherCompletedProgram';
// import { useBreakpointValue } from "@chakra-ui/react";

const TeacherChatPortal = lazy(() =>
  import(
    'version-two/pages/teacherDashboard/TeacherChatPortal/TeacherChatPortal'
  ),
);
const StudentChatPortal = lazy(() =>
  import(
    'version-two/pages/studentDashboard/studentChatPortal/StudentChatPortal'
  ),
);

function CommonRoutes() {
  return (
    <>
      <Route path={APP_VIDEO_PLAYER} element={<AppVideoPlayerWrapper />} />
      <Route path={APP_AUDIO_PLAYER} element={<AppAudioPlayer />} />
      <Route path={APP_PDF_VIEWER} element={<AppPdfViewer />} />
      <Route path={APP_IMAGE_VIEWER} element={<AppImageViewer />} />
      <Route
        path={APP_WEB_RECORDINGS_VIDEO_PLAYER_STUDENT}
        element={<WebRecordingsVideo />}
      />
      <Route
        path={APP_CLASS_RECORDINGS_VIDEO_PLAYER}
        element={<AppClassRecordingsWrapper />}
      />
    </>
  );
}

function TeacherRoutes() {
  return (
    <>
      <Route
        path={APP_TEACHER_LOGIN_SCREEN}
        element={<AppTeacherLoginScreen />}
      />
      <Route path={TEACHER_DASHBOARD} element={<TeacherProgramList />} />
      <Route
        path={TEACHER_DASHBOARD_COMPLETED}
        element={<TeacherCompletedProgram />}
      />
      <Route path={TEACHER_PROGRAM_DETAIL} element={<TeacherProgramDetail />} />
      <Route
        path={TEACHER_PROGRAM_DETAIL_CONTENT}
        element={<TeacherProgramDetail />}
      />
      <Route
        path={TEACHER_PROGRAM_DETAIL_INDIVIDUAL_STUDENT}
        element={<TeacherProgramDetailSingleStudent />}
      />
      <Route path={TEACHER_CHAT_LISTING} element={<TeacherChatList />} />
      {/* <Route path={TEACHER_CHAT_PORTAL} element={<TeacherChatPortal />} />
      <Route
        path={TEACHER_CHAT_PORTAL_GROUP_DETAIL}
        element={<TeacherChatPortal />}
      /> */}
      <Route
        path={TEACHER_CHAT_PORTAL}
        element={
          <TeacherChatPortal />
          // !ismobile ? <TeacherChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
      <Route
        path={TEACHER_CHAT_PORTAL_GROUP_DETAIL}
        element={
          <TeacherChatPortal />
          // !ismobile ? <TeacherChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
    </>
  );
}

function StudentRoutes() {
  return (
    <>
      <Route
        path={APP_STUDENT_LOGIN_SCREEN}
        element={<AppStudentLoginScreen />}
      />
      <Route path={STUDENT_DASHBOARD} element={<StudentProgramList />} />
      <Route
        path={STUDENT_PROGRAM_DETAIL}
        element={<StudentProgramDetailWrapper />}
      />
      <Route
        path={STUDENT_PROGRAM_DETAIL_CONTENT}
        element={<StudentProgramDetailWrapper />}
      />
      {/* <Route path={STUDENT_CHAT_PORTAL} element={<StudentChatPortal />} />
      <Route
        path={STUDENT_CHAT_PORTAL_GROUP_DETAIL}
        element={<StudentChatPortal />}
      /> */}
      <Route
        path={STUDENT_CHAT_PORTAL}
        element={
          <StudentChatPortal />
          // !ismobile ? <StudentChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
      <Route
        path={STUDENT_CHAT_PORTAL_GROUP_DETAIL}
        element={
          <StudentChatPortal />
          // !ismobile ? <StudentChatPortal /> : <Navigate to={redirectionalUrl} />
        }
      />
    </>
  );
}

function AppSwitch() {
  const setUserNameAndToken = useAppStore((state) => state.setUserNameAndToken);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  // const isMobile = useBreakpointValue({ base: true, xl: false });

  const navigate = useNavigate();

  const getRedirectionURL = () => {
    /**
     * This function is used to handle redirection url
     * TEACHER_DASHBOARD - if - Teacher Environment and Authenticated
     * STUDENT DASHBOARD - if - Authenticated and Not Teacher Environment
     * Else - Redirect to login screen based on environment
     * */
    if (isAuthenticated && isTeacherEnvironment()) {
      return TEACHER_DASHBOARD;
    }
    if (isAuthenticated && !isTeacherEnvironment()) {
      return STUDENT_DASHBOARD;
    }
    if (isTeacherEnvironment()) {
      return APP_TEACHER_LOGIN_SCREEN;
    }
    return APP_STUDENT_LOGIN_SCREEN;
  };

  useLayoutEffect(() => {
    isUserAuthenticated()
      .then((data) => {
        const token = getUserToken();
        setUserNameAndToken({
          token,
          name: data?.data?.data?.user?.name,
          userId: data?.data?.data?.user?.id,
        });

        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        navigate(getRedirectionURL());
        removeUserToken();
      });
  }, []);

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        {isAuthenticated !== null ? (
          <>
            <Route path="/" element={<Navigate to={getRedirectionURL()} />} />

            {/* {TeacherRoutes(getRedirectionURL(), isMobile)}
            {StudentRoutes(getRedirectionURL(), isMobile)} */}
            {TeacherRoutes()}
            {StudentRoutes()}
            {CommonRoutes()}
          </>
        ) : (
          <Route path="/" element={<AppLoader />} />
        )}
      </Routes>
    </Suspense>
  );
}
export default AppSwitch;
