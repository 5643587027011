import { Box, Flex, InputGroup, InputRightElement } from '@chakra-ui/react';
import AuthCard from 'version-two/components/AuthCard/AuthCard';
import AppText from 'version-two/components/Typography/AppText';
import AppInput from 'version-two/components/Typography/AppInput';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import AppButton from 'version-two/components/Typography/AppButton';
import AppLink from 'version-two/components/Typography/AppLink';
import useHandlePassword from 'version-two/hooks/useHandlePassword';
import AppTeacherNavbar from 'version-two/components/AppTeacherNavbar/AppTeacherNavbar';
import { useMutation } from 'react-query';
import useAppStore from 'version-two/store';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { STUDENT_DASHBOARD } from 'version-two/navigation/routes';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import { APP_STUDENT_LOGIN } from 'version-two/api/urls';
import isUserAuthenticated from 'version-two/api/utils/isUserAuthenticated';
import getUserToken from 'version-two/api/utils/getUserToken';
import StagingIdentifier from 'version-two/utils/stagingIdentifier';

function AppStudentLoginScreen() {
  // React Hooks
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
  });

  // React Query Hooks
  const { data, isLoading, isError, error, mutate } = useMutation((body) =>
    makePostRequest(APP_STUDENT_LOGIN, body),
  );

  // Zustand Hooks
  const setUserNameAndToken = useAppStore((state) => state.setUserNameAndToken);
  const setIsStudent = useAppStore((state) => state.setIsStudent);

  // React Navigation Hooks
  const navigate = useNavigate();

  // Custom Hooks
  const { showPassword, togglePasswordVisibility } = useHandlePassword();

  // Check if user is already authenticated
  useEffect(() => {
    isUserAuthenticated().then((resData) => {
      const token = getUserToken();
      setUserNameAndToken({
        token,
        name: resData?.data?.data?.user?.name,
        userId: resData?.data?.data?.user?.id,
      });
      navigate(STUDENT_DASHBOARD);
    });
  }, []);

  // Form Handlers
  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    mutate(formValues);
  };

  // Hooks to Handle form success and error
  useEffect(() => {
    const handleUserSigIn = async () => {
      if (!isLoading && !isError && data) {
        await setUserNameAndToken({
          token: data?.data?.auth?.token,
          name: data?.data?.user?.username,
          userId: data?.data?.data?.user?.id,
        });
        await setIsStudent();

        await localStorage.setItem('token', data?.data?.auth?.token);
        navigate(STUDENT_DASHBOARD);
      }
    };
    handleUserSigIn();
  }, [data, isLoading, isError]);

  return (
    <Box height="100vh">
      <AppTeacherNavbar />
      {/* <img src={BackgroundFlowerArt} alt="" /> */}
      <Flex
        height="90%"
        alignItems="center"
        justifyContent="center"
        flexFlow="column"
        style={{
          backgroundColor: '#FFECE5',
        }}
      >
        <AuthCard>
          <form onSubmit={handleFormSubmit}>
            <AppFlex
              customStyles={{
                flexFlow: 'column',
                justifyContent: 'center',
              }}
            >
              <AppText
                title="Login"
                variant="header"
                customStyles={{
                  textAlign: 'center',
                  mb: 5,
                }}
              />

              <AppText
                title={error?.response?.data?.data?.non_field_errors}
                variant="errorMessage"
              />

              <AppInput
                label="Email"
                name="username"
                type="email"
                placeholder="Email Address"
                customStyles={{
                  mb: 5,
                }}
                onChange={handleInputChange}
                value={formValues.username}
                isRequired
              />

              <AppText title="Password" variant="label" />
              <InputGroup>
                <AppInput
                  name="password"
                  type={showPassword ? 'text' : 'Password'}
                  placeholder="Password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  isRequired
                />
                <InputRightElement
                  onClick={togglePasswordVisibility}
                  cursor="pointer"
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </InputRightElement>
              </InputGroup>
              <AppButton
                isLoading={isLoading}
                title="Login"
                type="submit"
                customStyles={{ mt: 5 }}
              />
            </AppFlex>
          </form>
          <AppLink
            title="Forgot Password"
            variant="authLink"
            customStyles={{
              textAlign: 'center',
              mt: 5,
            }}
          />
        </AuthCard>
        <Box>
          <StagingIdentifier />
        </Box>
        <Box mt={5} textAlign="center">
          <AppText title="Reserved by Pratibha Music (OPC) Pvt." size="xs" />
          <AppLink
            href="https://www.voxguru.in/policy.html"
            title="Terms and Policy"
            size="xs"
          />
        </Box>
      </Flex>
    </Box>
  );
}
export default AppStudentLoginScreen;
