import create from 'zustand';

const useAppStore = create((set) => ({
  user_token: null,
  user_name: null,
  is_authenticated: false,
  isTrainer: false,
  isStudent: false,
  user_id: null,
  pitch: null,

  // Actions
  setUserNameAndToken: ({ token, name, userId }) =>
    set(() => ({
      user_token: token,
      user_name: name,
      is_authenticated: true,
      user_id: userId,
    })),
  removeUserNameAndToken: () =>
    set(() => ({
      user_token: null,
      user_name: null,
      is_authenticated: false,
      user_id: null,
    })),
  setIsStudent: (status = true) => set(() => ({ isStudent: status })),
  setIsTrainer: (status = true) => set(() => ({ isTrainer: status })),
  setPitch: (pitch) => set(() => ({ pitch })),
}));

export default useAppStore;
